
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.ampifire-banner {
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 0.25em;
    background-image: url('@/assets/img/slider-background.svg');
    background-size: contain;
    background-position-x: right;
    background-repeat: no-repeat;

    .ampifire-banner--content {
        position: relative;
        z-index: 1;
    }
}
